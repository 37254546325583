import HomePage from "pages/Home";
import LoginPage from "pages/Login";
import CloudscanPage from "pages/Cloudscan";
import VendorsPage from "pages/Vendors";
import ScansPage from "pages/Scans";
import PoliciesPage from "pages/Policies";
import EvidencePage from "pages/Evidence";
import ControlsPage from "pages/Controls";
import ComputersPage from "pages/Computers";
import OrgManagementPage from "pages/OrgManagement";
import IntegrationsPage from "pages/Integrations";
import UserProfilePage from "pages/UserProfile";
import PeoplePage from "pages/PeoplePage/PeoplePage";
import DetailedPerson from "pages/PeoplePage/DetailedPerson";
import PasswordManager from "pages/PasswordManager/PasswordManager";

import { isAuthenticated } from "utils/util.js";
import { Navigate } from "react-router-dom";
import { Layout } from "components/Layout";

import { GiSpellBook, GiSiren, GiPlug } from "react-icons/gi";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { HiHome } from "react-icons/hi";
import { FaUser } from "react-icons/fa";
import MFALoginPage from "pages/MFALogin";

const ProtectedRoute = () => {
  return isAuthenticated() ? <Layout /> : <Navigate to="/" />;
};

export const routes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/mfa",
    element: <MFALoginPage />,
  },
  {
    navRoute: true,
    element: <ProtectedRoute />,
    children: [
      {
        path: "/home",
        icon: HiHome,
        element: <HomePage />,
      },
      {
        path: "/compliance",
        icon: GiSpellBook,
        children: [
          {
            path: "policies",
            element: <PoliciesPage />,
          },
          {
            path: "controls",
            element: <ControlsPage />,
          },
          {
            path: "evidence",
            element: <EvidencePage />,
          },
        ],
      },
      {
        path: "/risk",
        icon: GiSiren,
        children: [
          {
            path: "cloud-scanner",
            element: <CloudscanPage />,
          },
          {
            path: "app-scanner",
            element: <ScansPage />,
          },
          {
            path: "vendors",
            element: <VendorsPage />,
          },
        ],
      },
      {
        path: "/organization",
        icon: HiMiniBuildingOffice2,
        children: [
          {
            path: "people",
            element: <PeoplePage />,
          },
          {
            path: "person",
            element: <DetailedPerson />,
          },
          {
            path: "computers",
            element: <ComputersPage />,
          },
          {
            path: "password-manager",
            element: <PasswordManager />,
          },
          {
            path: "settings",
            element: <OrgManagementPage />,
          },
        ],
      },
      {
        path: "/account",
        icon: FaUser,
        children: [
          {
            path: "user-profile",
            element: <UserProfilePage />,
          },
        ],
      },
      {
        path: "/integrations",
        icon: GiPlug,
        element: <IntegrationsPage />,
      },
    ],
  },
];
