import { FaCheck, FaRegTimesCircle } from "react-icons/fa";

export default function ToastContent({ status, text }) {
  const badge = {
    error: (
      <div className="p-2 bg-red-100 rounded-md">
        <FaRegTimesCircle className="text-red-700 size-4" />
      </div>
    ),
    success: (
      <div className="p-2 bg-green-100 rounded-md">
        <FaCheck className="text-green-700 size-4" />
      </div>
    ),
  };

  return (
    <div className="flex items-center gap-2 text-sm font-medium">
      {status && badge[status]}
      <div className="flex items-center gap-2">
        <span className="font-light">{text}</span>
      </div>
    </div>
  );
}
