import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { Badge, Accordion, Label, TextInput, Tooltip } from "flowbite-react";
import { FaLock, FaRegTimesCircle } from "react-icons/fa";
import { TbDeviceDesktopOff, TbDeviceDesktopPlus } from "react-icons/tb";
import { FaCheck } from "react-icons/fa";
import { FaCircleExclamation } from "react-icons/fa6";
import { BsShieldFillX } from "react-icons/bs";

import Button from "components/Button";
import Table from "components/Table";
import Link from "components/Link";
import Header from "components/Header";
import Spinner from "components/Spinner";
import DotDotDotMenu from "components/DotDotDotMenu";

import { getToken, prettyDateTime } from "utils/util";
import { apiFetch } from "utils/api";

function DeviceCheck({ device, prefix }) {
  const report = device.reports.find(rep => rep.name?.startsWith(prefix));
  console.log("REPORT", report);
  if (report === undefined) {
    return <FaCircleExclamation className="me-1 mt-1 text-gray-400" />;
  }

  const result = report.reports[0];
  if (result === undefined) {
    return <BsShieldFillX />;
  }

  if (Object.keys(result.columns).length === 0) {
    return <Tooltip content={prettyDateTime(result.last_fetched)}>
             <BsShieldFillX className="text-red-600 me-1.5" />
            </Tooltip>;
  }

  return  <Tooltip content={prettyDateTime(result.last_fetched)}>
            <FaCheck className="text-green-500 me-1.5" />
          </Tooltip>;
}

function DeviceCard({ device }) {
  return <div>
           <Header lv={3}>{device.hostname || (device.metadata.system_info || {}).computer_name}</Header>
           <p>{device.primary_mac}</p>
         </div>;
}

function FleetServerSetup() {
  const [formData, setFormData] = useState({
    url: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data' , formData);
    apiFetch("v0/org/device/setup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
  };

  return (
    <form >
      <hr />
      <div>
        <div><Label>Website URL</Label></div>
        <TextInput
          name="url"
          value={formData.url}
          onChange={handleChange}
          placeholder="Enter URL"
          required
        />
      </div>

      <div>
        <div><Label>Email address</Label></div>
        <TextInput
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter email"
          required
        />
      </div>

      <div>
        <div><Label>Password</Label></div>
        <TextInput
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
      </div>

      <Button variant="primary" type="submit" onClick={handleSubmit} className="my-2">
        Submit
      </Button>
    </form>
  );
}

const getOs = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes('win')) {
    return 'windows';
  } else if (userAgent.includes('mac')) {
    return 'macos';
  } else if (userAgent.includes('linux')) {
    if (userAgent.includes('ubuntu') || userAgent.includes('deb')) {
      return 'ubuntu';
    } else {
      return 'fedora'; // Default Linux to Fedora if not Ubuntu
    }
  }
  return undefined;
};

function DownloadLinks({ links }) {
  const [showList, setShowList] = useState(false);

  const userAgent = navigator.userAgent.toLowerCase();
  const os = getOs();

  return <div>
           {os && <Link href={links[os]} text={os} className="me-5"/>}
           {os !== undefined
            && (showList
                ? <Button onClick={e => setShowList(false)}>Cancel</Button>
                : <Button onClick={e => setShowList(true)}>Download Other</Button>)}

           {(os === undefined || showList)
            ? <ul>
                {Object.entries(links).map(
                  ([platform, link], ix) => (
                    <li key={`download-link-${ix}`}>
                      <Link href={link} text={platform}/>
                    </li>
                  ),
                )}
              </ul>
            : ""}
         </div>;
}

export default function AssetInventoryPage() {
  // const qClient = useQueryClient();
  // qClient.invalidateQueries({ queryKey: ["computersInfo"] });

  const {isPending, isSuccess, data} = useQuery({
    queryKey: ["computersInfo"],
    queryFn: () => apiFetch("v0/org/device")
  });

  const jwt = getToken();

  const shouldShowServerInterface = jwt.user.scopes.includes("godlike");

  function Device({ device }) {
    return <pre>{JSON.stringify(device, null, 2)}</pre>;
  };

  return <div>
           <Header>Computers</Header>
           {isPending && <Spinner />}
           {isSuccess && data
            && <div>
                 {data.download_links
                  ? <DownloadLinks links={data.download_links} />
                  : ""}
                 <Table
                   headers={["Device Name", "Owner", "Password", "Encrypted", "Antivirus", "Screen Lock", "Auto-Update", "Agent", ""]}
                   rows={data.devices.map((device, ix) => [
                     <DeviceCard device={device} />,
                     "-owner-",
                     <DeviceCheck device={device} prefix="password-" />,
                     <DeviceCheck device={device} prefix="encryption-check-" />,
                     <DeviceCheck device={device} prefix="antivirus-" />,
                     <DeviceCheck device={device} prefix="lock-screen-" />,
                     <DeviceCheck device={device} prefix="auto-update-" />,
                     <DeviceCheck device={device} prefix="agent-" />,
                     <DotDotDotMenu
                       items={[
                         [<FaLock />, "Lock", (e) => console.log(device)],
                         [<FaRegTimesCircle />, "Wipe", (e) => console.log(device)],
                         [<TbDeviceDesktopOff />, "Unassign", (e) => console.log(device)],
                         [<TbDeviceDesktopPlus />, "Assign", (e) => console.log(device)],
                       ]}
                     />
                   ])}
                 />
               </div>}
           {shouldShowServerInterface && <FleetServerSetup />}
         </div>;
}
