import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { Tabs } from "flowbite-react";
import { LuBadgeCheck } from "react-icons/lu";

import DotDotDotMenu from "components/DotDotDotMenu";
import Table from "components/Table";
import Header from "components/Header";
import FrameworkCard from "components/FrameworkCard";
import { Palette } from "components/DetailView";
import MarkdownEditor from "components/MarkdownEditor";
import Badge from "components/Badge";
import Button from "components/Button";
import ChangeOwnerModal from "components/ChangeOwnerModal";
import Spinner from "components/Spinner";

import soc2_icon from "images/soc2-icon.jpg";

import downloadjs from "downloadjs";

import { localeCompare, prettyDate, prettyDateTime, getToken } from "utils/util.js";
import { apiFetch, apiFetchRaw } from "utils/api";
import { IoEyeOutline } from "react-icons/io5";
import { PiDownload } from "react-icons/pi";
import { LiaUserEditSolid } from "react-icons/lia";
import { FaArrowRotateLeft } from "react-icons/fa6";
import LabelRow from "components/LabelRow";

function PolicyDetailsView({
  policy = {},
  control,
  onPublish,
  setDetailPolicy,
}) {
  const [generating, setGenerating] = useState(false);
  const historyData = useQuery({
    queryKey: [`policyHistory-${policy.policy_name}`],
    queryFn: () => policy.policy_name
      && apiFetch(`v0/policies/history?policy_name=${policy.policy_name}`)
      .then(res => res.history)
  });
  const handleGenerate = () => {
    setGenerating(true);
    generatePolicy().finally((_) => setGenerating(false));
  };

  const generatePolicy = (text = "") => {
    return apiFetch("v0/policies/generate", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        policy: policy.policy_name,
      }),
    }).then((res) => {
      if (res.policy) {
        setDetailPolicy(res.policy);
      }
      return res.policy;
    });
  };
  return (
    <div className="">
      <div className="mb-4">
        <label className="text-xxs uppercase font-semibold text-gray-500 mb-1">
          Description
        </label>
        <p className="text-sm">{control.description}</p>
      </div>
      <div className="my-4">
        <LabelRow
          className="mb-4"
          labels={["Status", "Owner", "Published date"]}
          values={[
            // Status
            policy && policy.published ? (
              <Badge icon="check" text="Published" color="green" />
            ) : (
              <Badge icon="draft" text="Draft" color="yellow" />
            ),
            // Owner
            <p className="text-sm text-gray-900 font-normal">
              {control.owner ? (
                <span>{control.owner}</span>
              ) : (
                <span className="text-gray-400">—</span>
              )}
            </p>,
            // Published date
            <p className="text-sm text-gray-900 font-normal">
              {policy && policy.published ? (
                prettyDate(policy.published)
              ) : (
                <span className="text-gray-400">—</span>
              )}
            </p>,
          ]}
        />
      </div>
      {/* Frameworks */}
      <div className="mb-4">
        <label className="text-xxs uppercase font-semibold text-gray-500 mb-1">
          Frameworks
        </label>
        <FrameworkCard icon={soc2_icon} framework={"SOC 2 2017"} />
      </div>
      <hr className="my-8" />

      {!Object.hasOwn(policy, "id") ? (
        <Button
          icon={<FaArrowRotateLeft />}
          variant="light"
          className="px-6"
          isLoading={generating}
          onClick={handleGenerate}
          tooltip="Recreate the existing details using the latest context within your organization."
        >
          Generate
        </Button>
      ) : <Tabs variant="underline" className="w-full">
            <Tabs.Item active title="Current">
              <MarkdownEditor
                key={policy.id}
                contents={policy.policy_text}
                isDisabled={!Object.hasOwn(policy, "id")}
                fetchDownload={(text) =>
                  apiFetchRaw("v0/policies/pdf", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ policy_id: policy.id }),
                  })
                }
                downloadName={`${control.frameworks.join("_")}_policy_${
            policy.policy_name
          }.pdf`}
                fetchSave={(text) =>
                  apiFetch("v0/policies/save", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      policy_name: policy.policy_name,
                      policy: text,
                    }),
                  })
                }
                fetchGenerate={generatePolicy}
                extraButtons={
                  <Button
                    icon={<LuBadgeCheck />}
                    onClick={(e) => onPublish(policy)}
                    tooltip="Publishing the policy will activate it across your organization"
                  >
                    Publish Policy
                  </Button>
                }
              />
            </Tabs.Item>
            <Tabs.Item title="History">
              {historyData.isPending
               ? <Spinner />
               : <Table
                   headers={["Created", "Published", "Signed By"]}
                   rows={historyData.data
                         .map(row => [
                           prettyDateTime(row.created),
                           row.published && prettyDateTime(row.published),
                           Object.keys(row.sign_offs).join(", "),
                         ])}/>}
            </Tabs.Item>
          </Tabs>}
    </div>
  );
}

function PolicyDropdown({ policy, control, handleView }) {
  const [downloadWaiting, setDownloadWaiting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const qClient = useQueryClient();

  const handlePDF = (policy) => {
    console.log("DOWNLOADING PDF", policy);
    setDownloadWaiting(true);
    return apiFetchRaw("v0/policies/pdf", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ policy_id: policy.id }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        downloadjs(blob, `policy_${policy.policy_name}.pdf`);
      })
      .finally((_) => setDownloadWaiting(false));
  };

  const handleChangeOwner = (newOwner) => {
    setDownloadWaiting(true);
    return apiFetch("v0/controls/update", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ctrl_id: control.id, owner: newOwner }),
    })
      .then((e) => {
        qClient.invalidateQueries({ queryKey: ["policiesInfo"] });
        setShowModal(false);
      })
      .finally((e) => setDownloadWaiting(false));
  };

  return (
    <>
      <DotDotDotMenu
        isLoading={downloadWaiting}
        items={[
          [<IoEyeOutline />, "View", (e) => handleView(policy)],
          policy && [<PiDownload />, "Download PDF", (e) => handlePDF(policy)],
          [<LiaUserEditSolid />, "Change Owner", (e) => setShowModal(true)],
        ]}
      />

      <ChangeOwnerModal
        show={showModal}
        onClose={(e) => setShowModal(false)}
        isLoading={downloadWaiting}
        title={control.name || control.control_id}
        description={control.description}
        currentOwner={control.owner}
        onChange={handleChangeOwner}
      />
    </>
  );
}

export default function PoliciesPage() {
  const { isPending, data } = useQuery({
    queryKey: ["policiesInfo"],
    queryFn: () => apiFetch("v0/policies"),
    onError: () => setDetailPolicy(undefined),
  });
  const [detailPolicy, setDetailPolicy] = useState(null);
  const qClient = useQueryClient();

  const setTheDetailedPolicy = (policy, p = {}) => {
    if (policy === undefined) {
      setDetailPolicy({ policy_name: p.control_id });
    } else {
      setDetailPolicy(policy);
    };
  };

  const policyControls =
    (!isPending &&
      Object.values(data.controls)
        .filter((ctrl) => ctrl.tags.includes("policy"))
        .sort(localeCompare((entry) => entry.control_id))) ||
    [];

  const policies =
    !isPending &&
    data.policies.reduce((acc, p) => {
      acc[p.policy_name] = p;
      return acc;
    }, {});

  const handlePublish = (policy) => {
    return apiFetch("v0/policies/publish", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ policy_id: policy.id }),
    }).then((res) => {
      setDetailPolicy(res.policy);
      return qClient.invalidateQueries({ queryKey: ["policiesInfo"] });
    });
  };

  return (
    <div className="h-auto">
      <Header lv={6} className={"mb-6 text-base"}>
        Policies
      </Header>

      <Table
        headers={[
          "ID",
          "Policy",
          "Status",
          "Published",
          "Owner",
          "Frameworks",
          "",
        ]}
        rows={
          policyControls.map((p) => {
            const policy = policies[p.control_id];

            return [
              <Header
                lv={6}
                className={"font-medium text-sm whitespace-nowrap"}
              >
                {p.control_id}
              </Header>,
              <>
                <Button
                  variant="link"
                  onClick={(e) =>
                    setTheDetailedPolicy(policy, p) && console.log(policy)
                  }
                >
                  {p.name || p.control_id}
                </Button>
                <p className="text-xs text-gray-500 truncate overflow-hidden min-w-full w-80 h-4">
                  {p.description}
                </p>
              </>,
              policy && policy.published ? (
                <Badge icon="check" text="Published" color="green" />
              ) : (
                <Badge icon="draft" text="Draft" color="yellow" />
              ),
              <span className="text-gray-900 text-sm font-normal">
                {policy && policy.published ? (
                  prettyDate(policy.published)
                ) : (
                  <span className="text-gray-400">—</span>
                )}
              </span>,
              <span className="font-medium">{p.owner}</span> || (
                <span className="text-gray-400">—</span>
              ),
              p.frameworks.join(", "),
              <div className="text-rights">
                <PolicyDropdown
                  policy={policy}
                  control={p}
                  handleView={(policy) => setTheDetailedPolicy(policy, p)}
                />
              </div>,
            ];
          }) || []
        }
        isLoading={isPending}
      />
      <Palette
        open={!!detailPolicy}
        width="60%"
        onClickClose={(e) => setDetailPolicy(null)}
        title={`Policy${
          detailPolicy !== null ? ": " + detailPolicy?.policy_name : ""
        }`}
      >
        {detailPolicy && (
          <PolicyDetailsView
            control={!isPending && data.controls[detailPolicy?.policy_name]}
            policy={detailPolicy}
            onPublish={handlePublish}
            setDetailPolicy={setTheDetailedPolicy}
          />
        )}
      </Palette>
    </div>
  );
}
