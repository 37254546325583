import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { Spinner, Tabs, TextInput, Tooltip } from "flowbite-react";
import { IoEyeOutline } from "react-icons/io5";
import { BsPencilSquare } from "react-icons/bs";
import {
  FaPaperclip,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import soc2_icon from "images/soc2-icon.jpg";

import ContentCard from "components/ContentCard";
import Table from "components/Table";
import Button from "components/Button";
import Link from "components/Link";
import Header from "components/Header";
import FilterBar from "components/FilterBar";
import HighlightedText from "components/HighlightedText";
import DotDotDotMenu from "components/DotDotDotMenu";
import { Palette } from "components/DetailView";
import LabelRow from "components/LabelRow";
import FrameworkCard from "components/FrameworkCard";
import Badge from "components/Badge";

import {
  getToken,
  capitalize,
  memoized,
  lastUrlSegment,
  prettyDate,
  allEqual,
} from "utils/util.js";
import { apiFetch } from "utils/api";
import { CustomTabTheme } from "utils/themes";

function TestTable({ control, evidence }) {
  let rows = [];
  if (control.control_id === "CM-2") {
    rows = Object.entries(evidence.evidence).map(([url, repo]) => [
      <Link
        href={url}
        text={lastUrlSegment(url)}
        className="whitespace-nowrap"
      />,
      repo.result ? (
        <Badge icon="check" text="PASSED" color="green" className="w-24" />
      ) : (
        <Badge icon="error" text="FAILED" color="red" className="w-24" />
      ),
      <>
        <Link href={repo.commit.url} className="whitespace-nowrap">
          Commit <pre>{repo.commit.sha.substring(0, 10)}...</pre>
        </Link>
        {prettyDate(evidence.created)}
      </>,
      <span className="text-gray-400">—</span>,
    ]);
  } else if (control.control_id === "CM-3") {
    rows = Object.entries(evidence.evidence).map(([url, repo]) => [
      <Link
        href={url}
        text={lastUrlSegment(url)}
        className="whitespace-nowrap"
      />,
      repo.warnings.length === 0 && repo.errors.length === 0 ? (
        <Badge icon="check" text="PASSED" color="green" className="w-24" />
      ) : (
        <Badge icon="error" text="FAILED" color="red" className="w-24" />
      ),
      <>
        <Link href={repo.commit.url} className="whitespace-nowrap">
          Commit <pre>{repo.commit.sha.substring(0, 10)}...</pre>
        </Link>
        {prettyDate(evidence.created)}
      </>,
      <span className="text-gray-400">—</span>,
    ]);
  } else if (evidence && evidence.passed) {
    rows = [
      [
        evidence.screenshot ? (
          <img
            src={`data:image/png;base64,${evidence.screenshot}`}
            alt={`Screenshot for control ${evidence.control_id}`}
            className="w-64"
          />
        ) : (
          ""
        ),
        evidence.passed ? (
          <Badge icon="check" text="PASSED" color="green" className="w-24" />
        ) : (
          <Badge icon="error" text="FAILED" color="red" className="w-24" />
        ),
        prettyDate(evidence.created),
        <span className="text-gray-400">—</span>,
      ],
    ];
  }
  return (
    <Table
      headers={["DETAILS", "STATUS", "LAST CHECKED", "OWNER"]}
      rows={rows}
    />
  );
}

const testCount = (control, evidence) => {
  if (control.control_id === "CM-2") {
    return [
      Object.values(evidence.evidence).filter((e) => e.result).length,
      Object.keys(evidence.evidence).length,
    ];
  } else if (control.control_id === "CM-3") {
    return [
      Object.values(evidence.evidence).filter(
        (e) => e.errors.length === 0 && e.warnings.length === 0,
      ).length,
      Object.keys(evidence.evidence).length,
    ];
  } else if (evidence && evidence.passed) {
    return [1, 1];
  }
  return [0, 0];
};

function TestsBadge({ control, evidence }) {
  const [passed, total] = testCount(control, evidence);

  return (
    <Tooltip
      content={
        total === 0
          ? "No tests assigned"
          : total === passed
          ? "All tests are passing"
          : `${total - passed} tests are failing`
      }
      className="max-w-xs text-center"
      placement="bottom"
    >
      {total === passed && total === 0 ? (
        <span className="text-gray-400">—</span>
      ) : (
        <div className="flex flex-wrap">
          <span className="me-1">
            {passed}/{total}
          </span>
          {passed === total ? (
            <FaCheckCircle className="mt-1 text-green-500" />
          ) : (
            <FaExclamationCircle className="mt-1 text-red-600" />
          )}
        </div>
      )}
    </Tooltip>
  );
}

function ControlDetails({ control, evidence, policy }) {
  return (
    <>
      <label className="text-xxs uppercase font-semibold text-gray-500 mb-1">
        Description
      </label>
      <p className="text-sm mb-5">{control.description}</p>

      <LabelRow
        className="mb-4"
        labels={["Status", "Owner", "Frameworks"]}
        values={[
          control && evidence && evidence[0] ? (
            allEqual(testCount(control, evidence[0])) ? (
              <Badge icon="check" text="Passing" color="green" />
            ) : (
              <Badge icon="error" text="Issue detected" color="red" />
            )
          ) : (
            <Badge icon="draft" color="yellow" text="Untested" />
          ),
          <span className="text-gray-400">—</span>,
          <FrameworkCard icon={soc2_icon} framework={"SOC 2 2017"} />,
        ]}
      />

      <hr className="my-6" />

      <Tabs variant="underline" theme={CustomTabTheme}>
        <Tabs.Item active title="Tests">
          <TestTable control={control} evidence={evidence && evidence[0]} />
        </Tabs.Item>
      </Tabs>
    </>
  );
}

function AddControlForm({ checkControls }) {
  const [ctrlId, setCtrlId] = useState("");
  const [name, setName] = useState("");
  const [frameworks, setFrameworks] = useState("SOC2");
  const [description, setDescription] = useState("");

  const handleSubmit = () => {
    const lst = frameworks.split(",").map((s) => s.trim());
    apiFetch("v0/controls/create", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        control_id: ctrlId,
        frameworks: lst,
        name,
        description,
      }),
    }).then(checkControls);
  };

  return (
    <form className="space-y-4">
      <div className="grid grid-cols-3 gap-4 mb-1">
        <TextInput
          placeholder="Control ID"
          value={ctrlId}
          onChange={(e) => setCtrlId(e.target.value)}
          className="w-full"
        />
        <TextInput
          placeholder="Control Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full"
        />
        <TextInput
          placeholder="Control Framework"
          value={frameworks}
          onChange={(e) => setFrameworks(e.target.value)}
          className="w-full"
        />
      </div>
      <TextInput
        placeholder="Control Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="w-full"
      />
      <Button onClick={handleSubmit}>Create</Button>
      <hr className="my-6 border-t border-gray-300" />
    </form>
  );
}

function ExistingControls({ controls, evidence, policies }) {
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({});
  const [detailControl, setDetailControl] = useState(null);

  const testStatus = memoized(
    (ctrl) => {
      if (controls[ctrl.control_id]) {
        if (controls[ctrl.control_id].every((ev) => ev.passed)) {
          return "passed";
        }
      } else if (policies[ctrl.control_id]) {
        if (policies[ctrl.control_id].signatures === {}) {
          return "pending";
        } else {
          return "passed";
        }
      }
      return "failed";
    },
    (ctrl) => ctrl.control_id,
  );

  const controlStatus = (ctrl) => {
    const tStat = testStatus(ctrl);
    if (tStat === "failed") {
      return "issue detected";
    }
    if (policies[ctrl.control_id]) {
      const tStat = testStatus(ctrl);
      if (tStat === "pending") {
        return "draft";
      }
    }
    return "ready";
  };

  return (
    <>
      <FilterBar
        className="mb-4"
        filters={{
          Status: ["Ready", "Draft", "Issue detected", "Excluded"],
          Owner: [],
          Tests: ["Passed", "Failed", "Pending"],
        }}
        onSearch={(text) => setSearch(text.toLowerCase())}
        onFilter={(filters) => setFilters(filters)}
      />
      <Table
        headers={["Id", "Details", "Status", "Frameworks", "Tests", ""]}
        rows={controls
          .filter(
            (ctrl) =>
              !filters.Status ||
              !filters.Status.length ||
              filters.Status.includes(capitalize(controlStatus(ctrl))),
          )
          .filter(
            (ctrl) =>
              !filters.Tests ||
              !filters.Tests.length ||
              filters.Tests.includes(capitalize(testStatus(ctrl))),
          )
          .filter(
            (ctrl) =>
              !search ||
              ctrl.description.toLowerCase().includes(search) ||
              (ctrl.owner || "").toLowerCase().includes(search) ||
              (ctrl.details || "").toLowerCase().includes(search) ||
              ctrl.control_id.toLowerCase().includes(search),
          )
          .map((ctrl) => [
            ctrl.control_id,
            <div>
              <Button variant="link" onClick={(_) => setDetailControl(ctrl)}>
                <HighlightedText
                  text={ctrl.name || ctrl.control_id}
                  search={search}
                />
              </Button>

              <p className="text-xs text-gray-500 truncate overflow-hidden min-w-full w-80 h-4">
                <HighlightedText text={ctrl.description} search={search} />
              </p>
            </div>,
            evidence[ctrl.control_id] ? (
              evidence[ctrl.control_id].every((ev) => ev.passed) ? (
                <Badge icon="check" color="green" text="Passing" />
              ) : (
                <Badge icon="error" color="red" text="Issue detected" />
              )
            ) : (
              <Badge icon="draft" color="yellow" text="Untested" />
            ),
            ctrl.frameworks.map((frm, ix) => (
              <Badge key={`framework-${ix}`} className="me-2" text={frm} />
            )),
            evidence[ctrl.control_id] ? (
              <TestsBadge
                control={ctrl}
                evidence={
                  evidence[ctrl.control_id] && evidence[ctrl.control_id][0]
                }
              />
            ) : (
              <div />
            ),
            <DotDotDotMenu
              items={[
                [<IoEyeOutline />, "View", (e) => setDetailControl(ctrl)],
                [
                  <BsPencilSquare />,
                  "Update details",
                  (e) => console.log("UPDATE DEETS", ctrl),
                ],
                [
                  <FaPaperclip />,
                  "Attach evidence",
                  (e) => console.log("ATTACH EVIDENCE", ctrl),
                ],
              ]}
            />,
          ])}
      />
      <Palette
        open={!!detailControl}
        width="70%"
        title={`Control${
          detailControl !== null
            ? ": " + (detailControl.name || detailControl.control_id)
            : ""
        }`}
        onClickClose={(_) => setDetailControl(null)}
      >
        {detailControl && (
          <ControlDetails
            control={detailControl}
            evidence={evidence[detailControl.control_id]}
            policy={policies[detailControl.control_id]}
          />
        )}
      </Palette>
    </>
  );
}

// {(jwt.user.scopes || []).includes("godlike") && (
//         <AddControlForm checkControls={checkControls} />
//       )}

export default function ControlsPage() {
  const policiesInfo = useQuery({
    queryKey: ["policiesInfo"],
    queryFn: () => apiFetch("v0/policies"),
  });
  const { isPending, data } = useQuery({
    queryKey: ["controlsInfo"],
    queryFn: () => apiFetch("v0/controls")
  });

  const jwt = getToken();

  return (
    <>
      <Header lv={6} className={"mb-6 text-base"}>
        Controls
      </Header>

      {isPending ? (
        <ContentCard title="Loading">
          <Spinner className="w-40 w-40" />
        </ContentCard>
      ) : (
        <ExistingControls
          controls={Object.values(data.controls).sort((a, b) => a.id <= b.id)}
          policies={policiesInfo.data.policies.reduce((acc, policy) => {
            acc[policy.policy_name] = policy;
            return acc;
          }, {})}
          evidence={data.evidence.reduce((acc, ev) => {
            if (!acc[ev.control_id]) {
              acc[ev.control_id] = [ev];
            } else {
              acc[ev.control_id].push(ev);
            }
            return acc;
          }, {})}
        />
      )}
    </>
  );
}
