/* <Table */
/*   rows={[[1, 2, 3, 4], */
/*          ["five", <Button variant="dark" onClick={_ => console.log("SIX")} label="6" />, "Seven", "EIGHT"]]} */
/* /> */
/* <Table */
/*   headers={["a", "b", "c", "d"]} */
/*   rows={[[1, 2, 3, 4], */
/*          ["five", <Button variant="dark" onClick={_ => console.log("SIX")} label="6" />, "Seven", "EIGHT"]]} */
/* /> */

import React, { useState } from "react";

import { Table as FbTable } from "flowbite-react";
import Spinner from "components/Spinner";
import { localeCompare } from "utils/util.js";

export default function Table({ headers, rows, isLoading = false }) {
  const [sort, setSort] = useState(null);
  const [sortDir, setSortDir] = useState("asc");

  const tblId = Math.round(Math.random() * 100000);

  const sliced = rows.slice();

  if (sort !== null) {
    sliced.sort(localeCompare((rw) => rw[sort]));
  }
  if (sortDir === "asc") {
    sliced.reverse();
  }

  return (
    <div className="border border-gray-200 rounded">
      <FbTable>
        {headers && (
          <FbTable.Head className="border-b border-gray-200">
            {headers.map((cell, ix) => (
              <FbTable.HeadCell
                key={`table-${tblId}-${ix}`}
                scope="col"
                className={`px-4 py-4 bg-gray-100 border-b border-gray-200 ${
                  cell ? "cursor-pointer" : ""
                }`}
                onClick={(e) => {
                  if (!cell) {
                    return undefined;
                  }
                  if (sort === ix && sortDir === "asc") {
                    console.log("   -- desc", ix);
                    setSortDir("desc");
                  } else if (sort === ix && sortDir === "desc") {
                    console.log("   -- unsorting", ix);
                    setSortDir("asc");
                    setSort(null);
                  } else {
                    console.log("   -- asc", ix);
                    setSort(ix);
                    setSortDir("asc");
                  }
                  return undefined;
                }}
              >
                <div className="flex items-center text-gray-500">
                  {cell}
                  {cell ? (
                    <svg
                      className="w-3 h-3 ms-1.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
              </FbTable.HeadCell>
            ))}
          </FbTable.Head>
        )}
        <FbTable.Body className="divide-y">
          {isLoading ? (
            <FbTable.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <FbTable.Cell className=" h-96" colSpan={headers.length}>
                <div className="flex justify-center items-center h-64">
                  <Spinner className="w-12 h-12 text-gray-400" />
                </div>
              </FbTable.Cell>
            </FbTable.Row>
          ) : (
            (sort === null ? rows : sliced).map((single_row, ix) => (
              <FbTable.Row
                key={`table-${tblId}-${ix}`}
                className="bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-700"
              >
                {single_row.map((cell, jx) => (
                  <FbTable.Cell
                    key={`table-${tblId}-${ix}-${jx}`}
                    className="px-4 py-4 text-gray-900"
                  >
                    {cell}
                  </FbTable.Cell>
                ))}
              </FbTable.Row>
            ))
          )}
        </FbTable.Body>
      </FbTable>
    </div>
  );
}
