import React from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import { IoIosCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { Tooltip } from "flowbite-react";

const statusConfig = {
  false: {
    Icon: FaCircleExclamation,
    text: "Incomplete",
    className: "text-red-600 size-4",
    tooltipText: "Incomplete",
    deviceToolText: "Device healthy",
  },
  true: {
    Icon: IoIosCheckmark,
    text: "Complete",
    className: "text-green-500 size-8",
    tooltipText: "Complete",
    deviceToolText: "Device not protected",
  },
  default: {
    Icon: RxCross2,
    text: "None assigned",
    className: "text-grey-100 size-4",
    tooltipText: "Complete",
    deviceToolText: "No device assigned",
  },
};

export default function PeopleStatusIndicator({
  status = "default",
  showText = false,
  isDeviceTab = false,
}) {
  const { Icon, text, className, tooltipText, deviceToolText } =
    statusConfig[status] || statusConfig.default;

  return (
    <div className="inline-flex items-center justify-center space-x-2">
      {showText || (status === "default" && isDeviceTab === false) ? (
        <Icon className={className} />
      ) : (
        <div>
          <Tooltip
            content={isDeviceTab ? deviceToolText : tooltipText}
            placement="bottom"
            className="mt-6"
          >
            <Icon className={className} />
          </Tooltip>
        </div>
      )}
      {showText && <span className="text-sm">{text}</span>}
    </div>
  );
}
