import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { TextInput } from "flowbite-react";
import Button from "components/Button";
import Spinner from "components/Spinner";
import Header from "components/Header";
import Table from "components/Table";
import { Palette } from "components/DetailView";
import DotDotDotMenu from "components/DotDotDotMenu";
import LabelRow from "components/LabelRow";
import Link from "components/Link";

import { IoEyeOutline } from "react-icons/io5";
import { FiRefreshCcw } from "react-icons/fi";
import { FaCheck, FaInfoCircle } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { BsShieldFillX } from "react-icons/bs";

import { apiFetch, appscan } from "utils/api";
import { getToken, prettyDate, capitalize } from "utils/util";

function PointSummary({info, low, warning, danger, centered}) {
  return <div className={`flex mx-3 ${centered ? 'items-center justify-center' : ''} text-sm`}>
           <div
             className={`flex items-center justify-center me-4 w-16 ${
                  info === 0 ? "opacity-20" : ""
                }`}
           >
             <FaInfoCircle className="text-blue-500 me-1.5" />{" "}
             <span className="">{info}</span>
           </div>
           <div
             className={`flex items-center justify-center me-4 w-16 ${
                  low === 0 ? "opacity-20" : ""
                }`}
           >
             <IoIosWarning className="text-yellow-300 me-1.5" />{" "}
             <span className="">{low}</span>
           </div>
           <div
             className={`flex items-center justify-center me-4 w-16 ${
                  warning === 0 ? "opacity-20" : ""
                }`}
           >
             <IoIosWarning className="text-orange-400 me-1.5" />{" "}
             {warning}
           </div>
           <div
             className={`flex items-center justify-center me-4 w-16 ${
                  danger === 0 ? "opacity-20" : ""
                }`}
           >
             <BsShieldFillX className="text-red-600 me-1.5" />{" "}
             {danger}
           </div>
         </div>;
}

function ScanDetailView({ scan }) {
  return <>
           <LabelRow
             labels={["URL", "LAST RUN", "STATUS", "SUMMARY"]}
             values={[
               <Link href={scan.root_url} target="_blank" />,
               prettyDate(scan.created),
               scan.state,
               <PointSummary
                 info={scan.scan_result.alerts?.informational?.length || 0}
                 low={scan.scan_result.alerts?.low?.length || 0}
                 warning={scan.scan_result.alerts?.medium?.length || 0}
                 danger={scan.scan_result.alerts?.high?.length || 0} />
             ]}
           />
           <Header lv={3} className="mb-3">Alerts</Header>
           {Object.entries(scan.scan_result.alerts)
            .map(([level, alerts], ix) =>{
              if (level === "total") {
                return "";
              };
              return <ul>
                       <li className="ms-5 mb-3"><Header lv={5}>{level}</Header></li>
                       <li className="ms-5 mb-3">
                         <ul className="list-disc ms-5">
                           {alerts.map(alert => <li><pre>{alert}</pre></li>)}
                         </ul>
                       </li>
                     </ul>;
            })}
           <Header lv={3} className="mt-5 mb-2">Requests</Header>
           {Object.entries(scan.scan_result.requests)
            .map(([url, summary], ix) =>
              <ul className="mb-3">
                <li className="ms-5 mb-3"><Header lv={5}><Link href={url} target="_blank" /></Header></li>
                <li className="ms-5">
                  <ul className="list-disc ms-5">
                    {summary && summary.map(line => <li><pre>{line}</pre></li>)}
                  </ul>
                </li>
              </ul>)}
         </>;
}

export default function ScansPage() {
  const [targetUrl, setTargetUrl] = useState("");
  const [scanWaiting, setScanWaiting] = useState(false);
  const [detailScan, setDetailScan] = useState(null);
  const jwt = getToken();
  const qClient = useQueryClient();
  const { isPending, error, data } = useQuery({
    queryKey: ["appscanInfo"],
    queryFn: () => appscan.list(jwt.user.org),
  });

  apiFetch("v0/info", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ keys: ["website", "extras"] }),
  }).then(res => targetUrl ? null : setTargetUrl(res.website));

  const handleBasicScan = (url) => {
    setScanWaiting(true);
    return appscan.basic(jwt.user.org, url)
      .then(_ => qClient.invalidateQueries({ queryKey: ["appscanInfo"] }))
      .finally(_ => setScanWaiting(false));
  };

  return <div>
           <Header className="mb-5">App Scanner</Header>
           {(isPending
             ? <Spinner />
             : <div>
                 <Header lv={3}>Basic Scans</Header>
                 <div className="w-full flex flex-row my-3">
                   <Button isLoading={scanWaiting} onClick={(e) => handleBasicScan(targetUrl)}>Scan</Button>
                   <TextInput onChange={(e) => setTargetUrl(e.target.value)} value={targetUrl} />
                 </div>
                 <Table
                   headers={["URL", "LAST RUN", "", ""]}
                   rows={Object.entries(data.scans.basic)
                         .map(([url, scan], ix) =>
                           [
                             <Link href={url} target="_blank" />,
                             prettyDate(scan.created),
                             <div onClick={(e) => setDetailScan(scan)}
                                  className="cursor-pointer">
                               <PointSummary
                                 info={scan.scan_result?.alerts?.informational?.length || 0}
                                 low={scan.scan_result?.alerts?.low?.length || 0}
                                 warning={scan.scan_result?.alerts?.medium?.length || 0}
                                 danger={scan.scan_result?.alerts?.high?.length || 0}
                                 centered={true} />
                             </div>,
                             <DotDotDotMenu
                               isLoading={scanWaiting}
                               items={[
                                 [<IoEyeOutline />, "View", (e) => setDetailScan(scan)],
                                 [<FiRefreshCcw />, "Rescan", (e) => handleBasicScan(scan.root_url)]
                               ]}
                             />
                           ])}
                 />
                 <Palette open={!!detailScan} width="60%" onClickClose={e => setDetailScan(null)}
                          title={detailScan && `${capitalize(detailScan.scan_type)} scan - ${detailScan.root_url}`}>
                   {detailScan && <ScanDetailView scan={detailScan}/>}
                 </Palette>
               </div>)}
         </div>;
}
