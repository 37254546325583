export default function Link({ href, text, className, children, target }) {
  return (
    <a
      href={href}
      target={target || "_self"}
      className={`text-blue-500 underline hover:no-underline hover:text-blue-700 ${className}`}
    >
      {children || text || href}
    </a>
  );
}
