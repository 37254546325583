import React, { useState } from "react";
import { Dropdown, TextInput } from "flowbite-react";

import { FaSearch } from "react-icons/fa";

function FilterDropdown({ label, states, onChange }) {
  const [activeFilters, setActiveFilters] = useState([]);
  const ctLbl = activeFilters.length ? ` (${activeFilters.length})` : "";

  return (
    <Dropdown label={`${label}${ctLbl}`} inline>
      {states.map((flt, ix) => (
        <Dropdown.Item
          key={`filter-bar-item-${label}-${ix}`}
          className={
            activeFilters.includes(flt)
              ? "text-white hover:text-black bg-purple-700"
              : undefined
          }
          onClick={(e) => {
            const updatedFilters = activeFilters.includes(flt)
              ? activeFilters.filter((filter) => filter !== flt)
              : [...activeFilters, flt];
            setActiveFilters(updatedFilters);
            return onChange(updatedFilters);
          }}
        >
          {flt}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
}

export default function FilterBar({
  className,
  filters,
  onFilter,
  onSearch,
  color = "white",
}) {
  const [activeFilters, setActiveFilters] = useState({});

  return (
    <div className={`flex flex-wrap ${className}`}>
      <TextInput
        icon={FaSearch}
        className="me-4"
        sizing="md"
        color={color}
        placeholder="Search"
        onChange={(e) => {
          console.log("CHANGING", e.target.value);
          onSearch(e.target.value);
        }}
      />
      {filters &&
        onFilter &&
        Object.entries(filters).map(([filterName, filterStates], ix) =>
          filterStates.length ? (
            <div
              className="flex flex-row me-4 py-2 px-4 text-sm rounded hover:bg-indigo-100 hover:text-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-100"
              key={`filter-dropdown-${ix}`}
            >
              <FilterDropdown
                key={`filter-dropdown-${ix}`}
                label={filterName}
                states={filterStates}
                onChange={(fs) => {
                  const fresh = { ...activeFilters };
                  fresh[filterName] = fs;
                  setActiveFilters(fresh);
                  return onFilter(fresh);
                }}
              />
            </div>
          ) : (
            ""
          ),
        )}
    </div>
  );
}
