import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { Spinner, TextInput, Tabs } from "flowbite-react";
import { FaEye, FaEdit, FaUpload, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { LuBadgeCheck } from "react-icons/lu";
import { CiImageOff } from "react-icons/ci";
import { LiaUserEditSolid } from "react-icons/lia";

import DotDotDotMenu from "components/DotDotDotMenu";
import Button from "components/Button";
import ContentCard from "components/ContentCard";
import { Palette } from "components/DetailView";
import Header from "components/Header";
import Table from "components/Table";
import FilterBar from "components/FilterBar";
import MarkdownEditor from "components/MarkdownEditor";
import LabelRow from "components/LabelRow";
import ConfirmModal from "components/ConfirmModal";
import Badge from "components/Badge";
import ChangeOwnerModal from "components/ChangeOwnerModal";
import OwnerDropdown from "components/OwnerDropdown";
import LetterIcon from "components/LetterIcon";

import { apiFetch, apiFetchRaw } from "utils/api";
import { prettyDate, prettyDateTime } from "utils/util";

import aws_icon from "images/vendors/aws.svg";
import gcp_icon from "images/vendors/gcp.svg";
import notion_icon from "images/vendors/notion.svg";
import slack_icon from "images/vendors/slack.svg";
import cloudfare_icon from "images/vendors/cloudflare.svg";
const ICONS = {
  amazon_web_services: aws_icon,
  cloudflare: cloudfare_icon,
  gcp: gcp_icon,
  notion: notion_icon,
  slack: slack_icon,
};

const vendorStatus = (vendor) =>
  Object.keys(vendor.sign_offs).length === 0 ? "Pending approval" : "Approved";

const statusToBadge = (status) =>
  status === "Pending approval" ? (
    <Badge icon="clock" color="yellow" text={status} />
  ) : (
    <Badge icon="check" color="green" text={status} />
  );

function VendorDetailsView({ vendor, onUpload }) {
  const [reqWaiting, setReqWaiting] = useState(false);
  const [reportFile, setReportFile] = useState(undefined);
  const [showUploadField, setShowUploadField] = useState(false);
  const [status, setStatus] = useState(vendorStatus(vendor));

  const historyData = useQuery({
    queryKey: [`policyHistory-${vendor.vendor}`],
    queryFn: () => apiFetch(`v0/vendor_risk_assessment/history?vendor_name=${vendor.vendor}`)
      .then(res => res.history)
  });

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("vendor_name", vendor.vendor);
    formData.append("file", reportFile);
    setReqWaiting(true);
    return apiFetch("v0/vendor_risk_assessment/assess", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        console.log("Report upload complete...");
        setStatus("Pending approval");
        onUpload(res);
      })
      .finally((_) => {
        setReqWaiting(false);
        setShowUploadField(false);
      });
  };

  const handleApprove = () => {
    setReqWaiting(true);
    return apiFetch("v0/vendor_risk_assessment/sign_off", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assessment_id: vendor.id }),
    })
      .then((res) => {
        console.log("Report approval complete");
        setStatus("Approved");
        onUpload(res);
      })
      .finally((_) => {
        setReqWaiting(false);
      });
  };

  return (
    <div className="flex flex-wrap space-y-6">
      <LabelRow
        labels={[
          "Vendor Name",
          "Status",
          "Owner",
          "Last Updated",
          "Next Assessment",
        ]}
        values={[
          vendor.vendor,
          statusToBadge(status),
          vendor.owner || vendor.created_by || (
            <span className="text-gray-400">—</span>
          ),
          prettyDate(vendor.created),
          <span className="text-gray-400">—</span>,
        ]}
      />
      <div className="w-full border-t-2 border-gray-200" />
      <Tabs variant="underline" className="w-full">
        <Tabs.Item active title="Current">
          {(showUploadField || !vendor.assessment) &&
           (reqWaiting ? (
             <div className="w-full">
               <Spinner className="w-32 h-32" />
             </div>
           ) : (
             <div className="w-full">
               <FileUploader
                 handleChange={(file) => setReportFile(file)}
                 name="file"
                 types={["PDF"]}
                 className="w-full"
               />
               <Button
                 icon={<FaUpload />}
                 disabled={!reportFile}
                 isLoading={reqWaiting}
                 className="my-3"
                 onClick={handleUpload}
               >
                 Upload
               </Button>
               {vendor.assessment ? (
                 <Button
                   icon={<MdOutlineCancel />}
                   className="my-3"
                   onClick={(_) => setShowUploadField(false)}
                 >
                   Cancel
                 </Button>
               ) : (
                 ""
               )}
             </div>
           ))}
          {vendor.assessment ? (
            <MarkdownEditor
              key={vendor.id}
              contents={vendor.assessment}
              fetchDownload={(text) =>
                apiFetchRaw("v0/vendor_risk_assessment/pdf", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ assessment_id: vendor.id }),
                })
              }
              fetchSave={(text) =>
                apiFetch("v0/vendor_risk_assessment/update", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    assessment_id: vendor.id,
                    edit: text,
                  }),
                })
              }
              extraButtons={
                <>
                  <Button
                    onClick={(_) => setShowUploadField(true)}
                    variant="light"
                    isLoading={reqWaiting}
                    icon={<FaUpload />}
                  >
                    Upload Report
                  </Button>
                  {status !== "Approved" && (
                    <ConfirmModal
                      icon={<LuBadgeCheck />}
                      label="Approve"
                      variant="light"
                      confirmLabel="Confirm & approve"
                      header="Approve Vendor Risk Assessment"
                      onConfirm={handleApprove}
                    >
                      <div className="prose">
                        Approving this vendor risk assessment acknowledges that:
                        <ul>
                          <li>
                            You have thoroughly evaluated this vendor's systems
                            against potential risks
                          </li>
                          <li>
                            This vendor meets your required standards, ensuring the
                            safety of your sensitive business information
                          </li>
                          <li>
                            The document is a fair summary of your findings and
                            evaluation
                          </li>
                        </ul>
                        Do you want to proceed?
                      </div>
                    </ConfirmModal>
                  )}
                </>
              }
            />
          ) : (
            ""
          )}
        </Tabs.Item>
        <Tabs.Item title="History">
          {historyData.isPending
           ? <Spinner />
           : (historyData.data
              && <Table
                   headers={["Created", "Owner", "Signed By"]}
                   rows={historyData.data
                         .map(row => [
                           prettyDateTime(row.created),
                           row.owner,
                           Object.keys(row.sign_offs).join(", ")
                         ])}/>)}
        </Tabs.Item>
      </Tabs>
    </div>
  );
}

function EditVendorPalette({ show, onClose, vendor }) {
  const qClient = useQueryClient();
  const [newVendorName, setNewVendorName] = useState("");

  const handleRenameVendor = (oldName, newName) => {
    console.log("Rename Vendor", oldName, newName);
    return apiFetch("v0/vendor_risk_assessment/rename", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ old_name: oldName, new_name: newName }),
    }).then((res) => {
      qClient.invalidateQueries({ queryKey: ["vendorsInfo"] });
      setNewVendorName("");
      onClose();
    });
  };

  return (
    <Palette
      open={show}
      onClickClose={onClose}
      title={`Rename Vendor - ${vendor.vendor}`}
      width="30%"
    >
      <Header lv={5}>New Name</Header>
      <TextInput
        className="mt-1 me-2"
        onChange={(e) => setNewVendorName(e.target.value)}
      ></TextInput>
      <Button
        disabled={!newVendorName}
        icon={<LiaUserEditSolid />}
        variant="dark"
        onClick={(e) => handleRenameVendor(vendor.vendor, newVendorName)}
        className="mt-5"
      >
        Rename Vendor
      </Button>
      <Button
        icon={<MdOutlineCancel />}
        className="my-3"
        variant="light"
        onClick={onClose}
      >
        Cancel
      </Button>
    </Palette>
  );
}

export default function VendorsPage() {
  const qClient = useQueryClient();
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [detailVendor, setDetailVendor] = useState(null);
  const [showNewVendorModal, setShowNewVendorModal] = useState(false);
  const [newVendorOwner, setNewVendorOwner] = useState("");
  const [newVendorName, setNewVendorName] = useState("");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState([]);
  const [reqWaiting, setReqWaiting] = useState(false);
  const [showEditVendorModal, setShowEditVendorModal] = useState(false);
  const { isPending, data } = useQuery({
    queryKey: ["vendorsInfo"],
    queryFn: () => apiFetch("v0/vendor_risk_assessment"),
  });

  const handleAddVendor = () => {
    console.log("ADDING NEW VENDOR!", newVendorName);
    return apiFetch("v0/vendor_risk_assessment/assess", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        vendor_name: newVendorName,
        owner: newVendorOwner,
      }),
    }).then((res) => {
      qClient.invalidateQueries({ queryKey: ["vendorsInfo"] });
      setNewVendorName("");
      setNewVendorOwner("");
      setShowNewVendorModal(false);
    });
  };

  const handleDeleteVendor = (assessment) => {
    console.log("Delete Vendor", assessment);
    return apiFetch("v0/vendor_risk_assessment/delete", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assessment_id: assessment.id }),
    }).then((res) => {
      qClient.invalidateQueries({ queryKey: ["vendorsInfo"] });
    });
  };

  if (isPending) {
    return (
      <ContentCard title="Loading">
        <Spinner className="w-40 w-40" />
      </ContentCard>
    );
  }

  const filtered = (assessments) => {
    return assessments
      .filter((assm) => !filters.length)
      .filter(
        (assm) =>
          !search ||
          assm.vendor.toLowerCase().includes(search) ||
          (assm.created_by && assm.created_by.toLowerCase().includes(search)),
      );
  };

  function VendorCard({ vendor }) {
    const vendor_key = vendor.vendor.trim().toLowerCase().replaceAll(" ", "_");

    return (
      <div
        onClick={(_) => setDetailVendor(vendor)}
        className="cursor-pointer flex flex-row space-x-2"
      >
        {ICONS[vendor_key] ? (
          <div className="border-2 border-gray-300 rounded-md">
            <img
              src={ICONS[vendor_key]}
              className="size-8 p-1"
              alt={`Vendor icon for ${vendor.vendor}`}
            />
          </div>
        ) : (
          <LetterIcon
            name={vendor_key}
            className="w-10 h-10 border-2 border-gray-300 rounded-md"
          />
        )}

        <div>
          <Button lv={5} variant="link" className={"text-indigo-700"}>
            {vendor.vendor}
          </Button>
          <p className="text-xs text-gray-400">Manually Added</p>
        </div>
      </div>
    );
  }

  const handleChangeOwner = (id, newOwner) => {
    console.log("CHANGING OWNER", id, newOwner);
    setReqWaiting(true);
    return apiFetch("v0/vendor_risk_assessment/update", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assessment_id: id, owner: newOwner }),
    })
      .then((e) => {
        qClient.invalidateQueries({ queryKey: ["vendorsInfo"] });
        setShowOwnerModal(false);
      })
      .finally((e) => setReqWaiting(false));
  };

  return (
    <>
      <div className="flex w-full justify-between align-center">
        <Header lv={6} className="flex items-center justify-center">
          Vendors
        </Header>

        <div className="">
          <Button
            size="sm"
            icon={<FaPlus />}
            variant="indigo"
            className="!mb-0 !me-0"
            onClick={(e) => setShowNewVendorModal(true)}
          >
            Add Vendor
          </Button>
        </div>
      </div>

      <div className="flex flex-row my-5">
        <div className="flex w-1/2">
          <FilterBar
            filters={{ Status: ["Approved", "Pending approval", "Analyzing"] }}
            onSearch={(text) => setSearch(text.trim().toLowerCase())}
            onFilter={(filters) =>
              setFilters(
                filters.Status.map((f) => f.split(" ")[0].toLowerCase()),
              )
            }
          />
        </div>
      </div>
      {data.vendor_assessments.length ? (
        <Table
          headers={[
            "Vendor",
            "Status",
            "Report",
            "Owner",
            "Last Updated",
            "Next Assessment",
            "",
          ]}
          rows={filtered(data.vendor_assessments).map((row) => [
            <VendorCard vendor={row} />,
            statusToBadge(vendorStatus(row)),
            (row.frameworks || []).join("") || (
              <span className="text-gray-400">—</span>
            ),
            row.owner || row.created_by || (
              <span className="text-gray-400">—</span>
            ),
            prettyDate(row.created),
            <span className="text-gray-400">—</span>,
            <>
              <DotDotDotMenu
                items={[
                  [<FaEye />, "View", (e) => setDetailVendor(row)],
                  [
                    <LiaUserEditSolid />,
                    "Change Owner",
                    (e) => {
                      setSelectedRow(row);
                      return setShowOwnerModal(true);
                    },
                  ],
                  [
                    <FaEdit />,
                    "Edit",
                    (e) => {
                      setSelectedRow(row);
                      setShowEditVendorModal(true);
                    },
                  ],
                  [<FaRegTrashAlt />, "Delete", (e) => handleDeleteVendor(row)],
                ]}
              />
            </>,
          ])}
        />
      ) : (
        <ContentCard
          title={"Complete vendor risk assessments in minutes, not hours"}
        >
          <p>
            Conducting annual vendor risk assessments is a core requirement for
            your SOC 2 certification (roughly X% of the report) and also for
            maintaining a strong security posture.
          </p>
          <p>
            Mycroft simplifies this process by analyzing your vendor SOC 2 & SOC
            3 reports, and generating comprehensive assessments for your review
            & approval.
          </p>
          <div className="flex flex-wrap mt-5">
            <Button
              variant={"light"}
              tooltip="Configuring integrations helps us scan for vulnerabilities while also getting you started on the risk assessments."
            >
              Configure integrations
            </Button>
            <Button
              variant={"light"}
              tooltip="Manually add the vendor details, then upload a report for Mycroft to analyze and generate an assessment"
            >
              Add vendor
            </Button>
          </div>
        </ContentCard>
      )}
      {selectedRow && (
        <ChangeOwnerModal
          show={showOwnerModal}
          onClose={(e) => setShowOwnerModal(false)}
          isLoading={reqWaiting}
          title={selectedRow.vendor.trim()}
          currentOwner={selectedRow.owner}
          onChange={(newOwner) => handleChangeOwner(selectedRow.id, newOwner)}
        />
      )}
      {selectedRow && (
        <EditVendorPalette
          show={showEditVendorModal}
          onClose={(e) => setShowEditVendorModal(false)}
          vendor={selectedRow}
        />
      )}
      <Palette
        open={showNewVendorModal}
        onClickClose={(e) => setShowNewVendorModal(false)}
        title="Add a Vendor"
        width="30%"
      >
        <Header lv={5}>Vendor Name</Header>
        <TextInput
          className="mt-1 me-2"
          onChange={(e) => setNewVendorName(e.target.value)}
        ></TextInput>
        <Header lv={5} className="mt-3">
          Owner
        </Header>
        <OwnerDropdown label="Owner" onChange={setNewVendorOwner} />
        <Button
          disabled={!newVendorName || !newVendorOwner}
          icon={<FaPlus />}
          variant="dark"
          onClick={handleAddVendor}
          className="mt-5"
        >
          Add Vendor
        </Button>
        <Button
          icon={<MdOutlineCancel />}
          className="my-3"
          variant="light"
          onClick={(e) => setShowNewVendorModal(false)}
        >
          Cancel
        </Button>
      </Palette>
      <Palette
        open={!!detailVendor}
        width="60%"
        onClickClose={(e) => setDetailVendor(null)}
        title={detailVendor && detailVendor.vendor}
      >
        {detailVendor && (
          <VendorDetailsView
            vendor={detailVendor}
            onUpload={(res) => {
              setDetailVendor(res.assessment);
              return qClient.invalidateQueries({ queryKey: ["vendorsInfo"] });
            }}
          />
        )}
      </Palette>
    </>
  );
}
