import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  Accordion, Badge, Button, Card, ListGroup, Spinner,
  FileInput, Textarea
} from "flowbite-react";

import downloadjs from "downloadjs";
import Markdown from "react-markdown";

import { getToken, localeCompare } from "utils/util.js";
import { apiFetch, apiFetchRaw } from "utils/api";
import { Tree, TreeNode } from "react-organizational-chart";

const STYLE = { width: "48rem", float: "left" };

// FIXME - code duplication with SignOffs
function ReportSignOffs({ user, report, checkControls }) {
  const handleSignOff = () => {
    console.log("SIGNING", report.id);
    apiFetch("v0/vendor_risk_assessment/sign_off", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assessment_id: report.id }),
    }).then((_) => checkControls());
  };

  return (
    <>
      {!(user.email in report.sign_offs) ? (
        <Button variant="success" onClick={handleSignOff}>
          Sign Off
        </Button>
      ) : (
        ""
      )}
      {Object.entries(report.sign_offs).map(([email, sig], ix) => (
        <Badge
          key={`sign-off-${report.id}-${ix}`}
          pill
          bg="success"
          className="mx-1"
        >
          {typeof sig === "object" ? (
            <>
              {sig.name}
              {sig.org_role ? `, ${sig.org_role}` : " "}({email}) -{" "}
              {new Date(sig.timestamp * 1000).toISOString()}
            </>
          ) : (
            email
          )}
        </Badge>
      ))}
    </>
  );
}

function SignOffs({ user, evidence, checkControls }) {
  const handleSignOff = () => {
    console.log("SIGNING", evidence.id, evidence.control_id);
    apiFetch("v0/evidence/sign_off", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ evidence_id: evidence.id }),
    }).then((_) => checkControls());
  };

  return (
    <>
      {!(user.email in evidence.sign_offs) ? (
        <Button variant="success" onClick={handleSignOff}>
          Sign Off
        </Button>
      ) : (
        ""
      )}
      {Object.entries(evidence.sign_offs).map(([email, sig], ix) => (
        <Badge
          key={`sign-off-${evidence.control_id}-${ix}`}
          pill
          bg="success"
          className="mx-1"
        >
          {typeof sig === "object" ? (
            <>
              {sig.name}
              {sig.org_role ? `, ${sig.org_role}` : " "}({email}) -{" "}
              {new Date(sig.timestamp * 1000).toISOString()}
            </>
          ) : (
            email
          )}
        </Badge>
      ))}
    </>
  );
}

function ControlHeader({
  name,
  checkControls,
  show_upload_interface,
  variant,
}) {
  const [assessWaiting, setAssessWaiting] = useState(false);
  const [file, setFile] = useState(null);
  // const [parameters, setParameters] = useState({});

  const handleAssess = (control_id) => {
    const formData = new FormData();
    formData.append("control_id", control_id);
    formData.append("file", file);
    // formData.append("parameters", JSON.stringify(parameters));
    console.log("RUNNING CHECK", control_id);
    setAssessWaiting(true);
    apiFetch("v0/controls/assess", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        console.log(res);
        return setAssessWaiting(false);
      })
      .then((_) => checkControls());
  };

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-2/12">
          <b>{name}</b>
        </div>
        <div className="w-6/12">
          {show_upload_interface ? (
            <FileInput
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          ) : (
            ""
          )}
        </div>
        <div>
          <Button
            className="mx-3"
            onClick={(ev) => handleAssess(name)}
            disabled={assessWaiting || (show_upload_interface && file === null)}
          >
            Assess Control
            {assessWaiting ? (
              <Spinner size="sm" />
            ) : (
              ""
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

function SingleCheck({
  name,
  description,
  checkControls,
  show_upload_interface,
}) {
  return (
    <Card style={STYLE} className="m-2">
      <ControlHeader
        name={name}
        checkControls={checkControls}
        show_upload_interface={show_upload_interface}
        variant="primary"
      />
      <div>{description}</div>
    </Card>
  );
}

function OrgChart({ tree }) {
  function Node({ name, attributes }) {
    return (
      <Card>
        <div>
          <b>{name}</b>
        </div>
        {/* <ListGroup className="list-group-flush"> */}
        {/*   <ListGroup.Item>{attributes.title}</ListGroup.Item> */}
        {/*   <ListGroup.Item>{attributes.dept}</ListGroup.Item> */}
        {/* </ListGroup> */}
      </Card>
    );
  }

  function renderSubTree(subTree, ix) {
    if (subTree.children && subTree.children.length > 0) {
      return (
        <TreeNode key={`tree-${ix}`} label={Node(subTree)}>
          {subTree.children.map((node, jx) =>
            renderSubTree(node, `${ix}-${jx}`),
          )}
        </TreeNode>
      );
    } else {
      return <TreeNode key={`leaf-${ix}`} label={Node(subTree)} />;
    }
  }

  return (
    <ListGroup.Item>
      <Tree label={Node(tree)}>{tree.children.map(renderSubTree)}</Tree>
    </ListGroup.Item>
  );
}

// FIXME - code duplication with ControlEditor
function ReportEditor({ user, report, name, value, checkControls }) {
  const [curValue, setCurValue] = useState(value);
  const [editing, setEditing] = useState(false);
  const [downloadWaiting, setDownloadWaiting] = useState(false);
  const [saveWaiting, setSaveWaiting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [reportId, setReportId] = useState(report.id);

  const handleSave = () => {
    setSaveWaiting(true);
    return apiFetch("v0/vendor_risk_assessment/update", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        assessment_id: reportId,
        edit: curValue,
      }),
    })
      .then((res) => {
        setDirty(false);
        setReportId(res.assessment.id);
        return checkControls();
      })
      .finally((_) => setSaveWaiting(false));
  };

  const handleDelete = () => {
    console.log("Called DELETE", reportId);
    return apiFetch("v0/vendor_risk_assessment/delete", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        assessment_id: reportId,
      }),
    }).then((res) => {
      console.log("DELETE RESPONDED!");
      return checkControls();
    });
  };

  const handlePDF = () => {
    setDownloadWaiting(true);
    apiFetchRaw("v0/vendor_risk_assessment/pdf", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        assessment_id: reportId,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        downloadjs(
          blob,
          `${user.org}_vendor_risk_management_${report.vendor}.pdf`,
        );
        return setDownloadWaiting(false);
      });
  };

  return (
    <>
      <Button.Group className="mb-4">
        <Button onClick={(ev) => console.log(ev)}>Generate</Button>
        <Button onClick={(ev) => setEditing(!editing)}>
          {editing ? "Preview" : "Edit"}
        </Button>
        <Button onClick={(ev) => handleSave()} disabled={!dirty || saveWaiting}>
          Save
        </Button>
        <Button onClick={(ev) => handlePDF()} disabled={downloadWaiting}>
          PDF
          {downloadWaiting ? (
            <Spinner size="sm" />
          ) : (
            ""
          )}
        </Button>
        <Button onClick={(ev) => handleDelete()} variant="danger">
          Delete
        </Button>
      </Button.Group>
      {editing ? (
        <Textarea
          as="textarea"
          value={curValue}
          rows={15}
          onChange={(ev) => {
            setDirty(true);
            return setCurValue(ev.target.value);
          }}
        />
      ) : (
        <Markdown className="m-3 prose">{curValue}</Markdown>
      )}
    </>
  );
}

function ControlEditor({ user, control, name, value, checkControls }) {
  const [curValue, setCurValue] = useState(value);
  const [editing, setEditing] = useState(false);
  const [downloadWaiting, setDownloadWaiting] = useState(false);
  const [saveWaiting, setSaveWaiting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [controlId, setControlId] = useState(control.id);

  const handleSave = () => {
    setSaveWaiting(true);
    const edits = {};
    edits[name] = curValue;
    return apiFetch("v0/controls/evidence/update", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        evidence_id: controlId,
        edits: edits,
      }),
    })
      .then((res) => {
        setDirty(false);
        setControlId(res.control.id);
        return checkControls();
      })
      .finally((_) => setSaveWaiting(false));
  };

  const handlePDF = () => {
    setDownloadWaiting(true);
    apiFetchRaw("v0/evidence/pdf", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        evidence_id: controlId,
        evidence_key: name,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        const label = control.evidence.label
          ? `_${control.evidence.label}`
          : "";
        downloadjs(
          blob,
          `${user.org}_evidence_${control.control_id}${label}.pdf`,
        );
        return setDownloadWaiting(false);
      });
  };

  return (
    <>
      <Button.Group className="mb-4">
        <Button onClick={(ev) => console.log(ev)}>Generate</Button>
        <Button onClick={(ev) => setEditing(!editing)}>
          {editing ? "Preview" : "Edit"}
        </Button>
        <Button onClick={(ev) => handleSave()} disabled={!dirty || saveWaiting}>
          Save
        </Button>
        <Button onClick={(ev) => handlePDF()} disabled={downloadWaiting}>
          PDF
          {downloadWaiting ? (
            <Spinner size="sm" />
          ) : (
            ""
          )}
        </Button>
      </Button.Group>
      {editing ? (
        <Textarea
          as="textarea"
          value={curValue}
          rows={15}
          onChange={(ev) => {
            setDirty(true);
            return setCurValue(ev.target.value);
          }}
        />
      ) : (
        <Markdown className="m-3 prose">{curValue}</Markdown>
      )}
    </>
  );
}

function EvidencePiece({ user, control, name, value, checkControls }) {
  if (name === "org_chart") {
    const reformat = (tree) => {
      return {
        name: tree.name,
        attributes: {
          title: tree.title,
          dept: tree.department,
        },
        children: tree.reports.map(reformat),
      };
    };

    const forest = Object.entries(value).map(([id, subTree]) =>
      reformat(subTree),
    );

    return (
      <>
        {forest.map((subTree, ix) => (
          <OrgChart key={`tree-${ix}`} tree={subTree} />
        ))}
      </>
    );
  } else if (name === "assessment") {
    return (
      <ControlEditor
        user={user}
        control={control}
        name={name}
        value={value}
        checkControls={checkControls}
      />
    );
  } else if (name === "template") {
    return (
      <ControlEditor
        user={user}
        control={control}
        name={name}
        value={value.replace(/^`+markdown|`+$/g, "")}
        checkControls={checkControls}
      />
    );
  }
  return (
    <ListGroup.Item>
      <h4>{name}</h4>
      {JSON.stringify(value)}
    </ListGroup.Item>
  );
}

function ControlEvidence({ control, user, checkControls }) {
  if (control.evidence === {}) {
    return "";
  }

  if (control.control_id === "CM-3") {
    const repoCount = Object.keys(control.evidence).length;
    const summary = Object.values(control.evidence).reduce(
      (memo, elem) => {
        return {
          warnings: memo.warnings + elem.warnings.length,
          errors: memo.errors + elem.errors.length,
        };
      },
      { warnings: 0, errors: 0 },
    );
    return (
      <Accordion collapseAll>
        <Accordion.Panel>
          <Accordion.Title className="flex flex-col space-y-4">
            <h6>
              Summary: {repoCount} repos, {summary.warnings} warnings,{" "}
              {summary.errors} errors
            </h6>
          </Accordion.Title>
          <Accordion.Content>
            <ListGroup>
              {Object.entries(control.evidence).map(([url, status], ix) => (
                <ListGroup.Item key={ix}>
                  <h6>
                    <a href={url}>{url.split("/").reverse()[0]}</a>
                  </h6>
                  {!status.errors.length && !status.warnings.length && (
                    <Badge bg="success">✓</Badge>
                  )}
                  {status.errors.map((err) => (
                    <Badge bg="danger" key={`err-${ix}`}>
                      {err.pull_request && (
                        <>
                          <a href={err.pull_request}>PR</a> -{" "}
                        </>
                      )}
                      {err.error}
                    </Badge>
                  ))}
                  {status.warnings.map((err, ix) => (
                    <Badge bg="warning" key={`warn-${ix}`}>
                      {err.pull_request && (
                        <>
                          <a href={err.pull_request}>PR</a> -{" "}
                        </>
                      )}
                      {err.warning}
                    </Badge>
                  ))}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    );
  }

  if (control.control_id === "CM-2") {
    return (
      <Accordion collapseAll>
        {Object.entries(control.evidence).map(([url, status], ix) => (
          <Accordion.Panel key={`cm2-summary-${ix}`}>
            <Accordion.Title>
              {status.result ? (
                <Badge className="me-2" bg="success">
                  ✓
                </Badge>
              ) : (
                <Badge className="me-2" bg="danger">
                  x
                </Badge>
              )}
              {url.split("/").reverse()[0]}
            </Accordion.Title>
            <Accordion.Content>
              <h6>
                {" "}
                <a href={url}>{url.split("/").reverse()[0]}</a>
              </h6>
              {status.error && (
                <Badge className="me-2" bg="danger">
                  {status.error}
                </Badge>
              )}
              <Markdown className="m-3 prose">{status.summary}</Markdown>
            </Accordion.Content>
          </Accordion.Panel>
        ))}
      </Accordion>
    );
  }

  return (
    <ListGroup className="list-group-flush">
      {Object.entries(control.evidence).map(([key, val], ix) => (
        <EvidencePiece
          key={`evidence-pair-${ix}`}
          checkControls={checkControls}
          user={user}
          control={control}
          name={key}
          value={val}
        />
      ))}
    </ListGroup>
  );
}

function SingleCheckedControl({
  user,
  description,
  control,
  checkControls,
  show_upload_interface,
}) {
  return (
    <Card
      border={control.passed ? "success" : "danger"}
      text={control.passed ? "success" : "danger"}
      style={STYLE}
      className="m-2"
    >
      <ControlHeader
        name={control.control_id}
        checkControls={checkControls}
        show_upload_interface={show_upload_interface}
        variant={control.passed ? "success" : "danger"}
      />

      <div>
        <SignOffs
          user={user}
          evidence={control}
          checkControls={checkControls}
        />
      </div>

      {control.screenshot ? (
        <img
          variant="top"
          alt="Screenshot evidence"
          src={`data:image/png;base64,${control.screenshot}`}
        />
      ) : (
        ""
      )}

      <ControlEvidence
        user={user}
        control={control}
        checkControls={checkControls}
      />
      <div>{description}</div>
      <div>{control.created}</div>
    </Card>
  );
}

function VendorName({ user, report, checkControls }) {
  const [curValue, setCurValue] = useState(report.vendor);
  const [editing, setEditing] = useState(false);
  const [saveWaiting, setSaveWaiting] = useState(false);
  const [dirty, setDirty] = useState(false);

  const handleSave = () => {
    setSaveWaiting(true);
    return apiFetch("v0/vendor_risk_assessment/rename", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        assessment_id: report.id,
        vendor: curValue,
      }),
    })
      .then((res) => {
        setDirty(false);
        return checkControls();
      })
      .finally((_) => {
        setSaveWaiting(false);
        return setEditing(false);
      });
  };

  return (
    <>
      {editing ? (
        dirty ? (
          <>
            <Button
              className="me-1"
              onClick={(ev) => handleSave()}
              disabled={saveWaiting}
            >
              Save
              {saveWaiting ? (
                <Spinner size="sm" />
              ) : (
                ""
              )}
            </Button>
            <Button
              className="me-2"
              onClick={(ev) => {
                setCurValue(report.vendor);
                setEditing(false);
              }}
              disabled={saveWaiting}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button className="me-2" onClick={(ev) => setEditing(false)}>
            Cancel
          </Button>
        )
      ) : (
        <Button
          className="me-2"
          onClick={(ev) => {
            ev.preventDefault();
            setEditing(!editing);
          }}
        >
          Edit
        </Button>
      )}

      {editing ? (
        <input
          type="text"
          value={curValue}
          onChange={(e) => {
            setDirty(true);
            return setCurValue(e.target.value);
          }}
        />
      ) : (
        curValue
      )}
    </>
  );
}

function Assessments({ user, reports, checkControls }) {
  const control_id = "VRM-2";
  return (
    <Card
      style={STYLE}
      className="m-2"
      border={reports[0].passed ? "success" : "danger"}
      text={reports[0].passed ? "success" : "danger"}
    >
      <ControlHeader
        name={control_id}
        checkControls={checkControls}
        show_upload_interface={true}
        variant="primary"
      />
      <Accordion collapseAll>
        {reports.map((report, ix) => {
          const key = `report-${ix}`;
          return (
            <Accordion.Panel key={key}>
              <Accordion.Title>{report.vendor}</Accordion.Title>
              <Accordion.Content>
                <VendorName
                  user={user}
                  report={report}
                  checkControls={checkControls}
                />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    <ReportSignOffs
                      user={user}
                      report={report}
                      checkControls={checkControls}
                    />
                  </ListGroup.Item>
                  <ReportEditor
                    user={user}
                    report={report}
                    name={report.vendor}
                    value={report.assessment}
                    checkControls={checkControls}
                  />
                </ListGroup>
              </Accordion.Content>
            </Accordion.Panel>
          );
        })}
      </Accordion>
    </Card>
  );
}

function PolicyControl({ user, policy, name, description, checkControls }) {
  const [generateWaiting, setGenerateWaiting] = useState(false);
  const color =
    policy === undefined
      ? "danger"
      : Object.entries(policy.sign_offs).length
        ? "success"
        : "warning";

  const handleGenerate = () => {
    setGenerateWaiting(true);
    apiFetch("v0/policies/generate", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        policy: name,
      }),
    })
      .then((_) => checkControls())
      .finally((_) => setGenerateWaiting(false));
  };

  return (
    <Card border={color} text={color} style={STYLE} className="m-2">
      <div>
        <div className="flex flex-wrap">
          <div className="w-2/12">{name}</div>
          <div className="w-6/12">
            {policy &&
              policy.sign_offs &&
              Object.entries(policy.sign_offs).map(([email, sig], ix) => (
                <Badge
                  key={`policy-sign-off-${policy.control_id}-${ix}`}
                  pill
                  bg="success"
                  className="mx-1"
                >
                  {typeof sig === "object" ? (
                    <>
                      {sig.name}
                      {sig.org_role ? `, ${sig.org_role}` : " "}({email}) -{" "}
                      {new Date(sig.timestamp * 1000).toISOString()}
                    </>
                  ) : (
                    email
                  )}
                </Badge>
              ))}
          </div>
          <div>
            {policy === undefined && (
              <Button
                onClick={(ev) => handleGenerate()}
                disabled={generateWaiting}
              >
                Generate
                {generateWaiting ? (
                  <Spinner size="sm" />
                ) : (
                  ""
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div>{description}</div>
    </Card>
  );
}

function Controls({
  controls,
  evidence,
  policies,
  assessments,
  checkControls,
}) {
  const checked_ids = new Set(evidence.map((c) => c.control_id));
  const jwt = getToken();

  const policy_map = Object.fromEntries(
    Object.entries(controls).filter(([key, _]) => key.startsWith("POL-")),
  );
  const org_policy_map = Object.groupBy(policies, (c) => c.policy_name);

  return (
    <>
      {evidence.map((control, ix) => {
        return (
          <SingleCheckedControl
            key={`control-${ix}`}
            control={control}
            user={jwt.user}
            checkControls={checkControls}
            show_upload_interface={false}
            description={controls[control.control_id].description}
          />
        );
      })}
      {assessments.length ? (
        <Assessments
          reports={assessments}
          checkControls={checkControls}
          user={jwt.user}
        />
      ) : (
        ""
      )}
      {Object.entries(policy_map)
        .sort(localeCompare((pair) => pair[0]))
        .map(([policy_name, control], ix) => (
          <PolicyControl
            user={jwt.user}
            key={`policy-${ix}`}
            checkControls={checkControls}
            policy={(org_policy_map[policy_name] || [])[0]}
            name={policy_name}
            description={control.description}
          />
        ))}
      {Object.entries(controls).map(([control_id, control], ix) => {
        if (
          checked_ids.has(control_id) ||
          control_id.startsWith("POL-") ||
          (control_id === "VRM-2" && assessments.length)
        ) {
          return "";
        } else {
          return (
            <SingleCheck
              key={`requirement-${ix}`}
              name={control_id}
              description={control.description}
              checkControls={checkControls}
              show_upload_interface={control_id === "VRM-2"}
            />
          );
        }
      })}
    </>
  );
}

export default function EvidencePage() {
  const qClient = useQueryClient();
  const controlsInfo = useQuery({
    queryKey: ["integrationsInfo"],
    queryFn: () => apiFetch("v0/info").then

  });

  const [infoRes, setInfoRes] = useState(null);

  const checkControls = () =>
    apiFetch("v0/info").then((res) => setInfoRes(res));

  if (infoRes === null) {
    checkControls();
  }

  return (
    <>
      {infoRes && infoRes.evidence ? (
        <Controls
          controls={infoRes.controls}
          evidence={infoRes.evidence}
          policies={infoRes.policies}
          assessments={infoRes.vendor_assessments}
          checkControls={checkControls}
        />
      ) : (
        ""
      )}
    </>
  );
}
