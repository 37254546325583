import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import bitbucket_icon from "images/bitbucket-icon.png";

import { getRawToken } from "utils/util.js";
import { apiFetch } from "utils/api";
import { fetchIntegrations, UnifiedConnection } from "components/Unified.js";

import CloudConnection from "components/CloudConnection";
import Header from "components/Header";

export default function IntegrationsPage() {
  const qClient = useQueryClient();
  const {isPending, data } = useQuery({
    queryKey: ["integrationsInfo"],
    queryFn: () => apiFetch("v0/info").then
  });
  const tokenNameInfo = useQuery({
    queryKey: ["tokenNameInfo"],
    queryFn: () => apiFetch("v0/org/creds/names")
  });
  const updateInfo = () => {
    qClient.invalidateQueries({queryKey: ["integrationsInfo"]});
    qClient.invalidateQueries({queryKey: ["tokenNameInfo"]});
  };

  const [unifiedRes, setUnifiedRes] = useState(null);
  useEffect(() => {
    fetchIntegrations().then((dat) => {
      console.log("UNIFIED", dat);
      setUnifiedRes(dat || []);
    });
  }, []);

  const jwt = getRawToken();
  if (jwt === undefined) {
    return <h2>Authentication Error</h2>;
  }

  const isConnected = (service) =>
    data &&
    data.extras &&
    data.extras.integrations &&
    ((data.extras.integrations.hris &&
      data.extras.integrations.hris[service]) ||
      (data.extras.integrations.messaging &&
        data.extras.integrations.messaging[service]) ||
      (data.extras.integrations.repo &&
        data.extras.integrations.repo[service]));

  const handleDisconnect = (connection_id) => {
    console.log("DISCONNECTING", connection_id);
    apiFetch("v0/unified/disconnect", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ connection_id: connection_id }),
    }).then(updateInfo);
  };

  return (
    <>
      <Header lv={2} className="mb-8">Integrations</Header>

      <div>
        <Header lv={2} className="mb-4">Repo Systems</Header>

        <div className="flex flex-wrap mb-6">
          <CloudConnection
            updateInfo={updateInfo}
            existing_connections={tokenNameInfo.data.token_names}
            name="Bitbucket"
            icon={bitbucket_icon}
            key_a="bitbucket_token"
            key_b="bitbucket_org"
            docs="https://support.atlassian.com/bitbucket-cloud/docs/create-a-workspace-access-token/"
          />

          {unifiedRes &&
            unifiedRes
              .filter((dat) => ["github"].includes(dat.type))
              .map((dat) => (
                <UnifiedConnection
                  data={dat}
                  connection={isConnected(dat.type)}
                  handleDisconnect={handleDisconnect}
                  success_redirect={`${process.env.REACT_APP_SERVER_API}/api/v0/callback/unified/repo/login`}
                  state={jwt}
                  extra_param="github_org"
                  environment={"Production"}
                  key={dat.type}
                />
              ))}
        </div>
      </div>

      <hr className="mt-6 mb-8" />

      <div>
        <Header lv={2} className="mb-4">HR Systems</Header>

          <div className="flex flex-wrap mb-6">
            {unifiedRes &&
              unifiedRes
                .filter(
                  (dat) =>
                    !["slack", "discord", "github", "bitbucket"].includes(dat.type),
                )
                .map((dat) => (
                  <UnifiedConnection
                    data={dat}
                    connection={isConnected(dat.type)}
                    handleDisconnect={handleDisconnect}
                    success_redirect={`${process.env.REACT_APP_SERVER_API}/api/v0/callback/unified/hris/login`}
                    state={jwt}
                    key={dat.type}
                  />
                ))}
          </div>
      </div>

      <hr className="mt-6 mb-8" />

      <div>
        <Header lv={2} className="mb-4">Messaging Systems</Header>

          <div className="flex flex-wrap mb-6">
            {unifiedRes &&
            unifiedRes
              .filter((dat) => ["slack", "discord"].includes(dat.type))
              .map((dat) => (
                <UnifiedConnection
                  data={dat}
                  connection={isConnected(dat.type)}
                  handleDisconnect={handleDisconnect}
                  success_redirect={`${process.env.REACT_APP_SERVER_API}/api/v0/callback/unified/messaging/login`}
                  state={jwt}
                  environment={process.env.REACT_APP_UNIFIED_ENVIRONMENT}
                  key={dat.type}
                />
              ))}
          </div>
      </div>
    </>
  );
}
