function objectToQueryString(obj) {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (value === null || value === undefined) {
        return encodeURIComponent(key);
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
}

function removeKey(obj, keyToRemove) {
  const { [keyToRemove]: _, ...newObj } = obj;
  return newObj;
}

const apiUrl = (endpoint, base = process.env.REACT_APP_SERVER_API) =>
  `${base}/api/${endpoint}`;

export const apiAnonPost = (endpoint, params) => {
  return fetch(apiUrl(endpoint), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
};

export const apiFetchRaw = (
  endpoint,
  options,
  base = process.env.REACT_APP_SERVER_API,
) => {
  const inpHeaders = (options || {}).headers || {};
  const headers = Object.assign({}, inpHeaders, {
    Authorization: `Bearer ${sessionStorage.getItem("mycroft-jwt")}`,
  });
  const opts = Object.assign({}, options || {}, { headers: headers });
  return fetch(apiUrl(endpoint, base), opts).then((res) => {
    if (res.status === 403) {
      sessionStorage.removeItem("mycroft-jwt");
      window.location.href = "/";
    }
    return res;
  });
};

export const apiFetch = (
  endpoint,
  options,
  base = process.env.REACT_APP_SERVER_API,
) => {
  return apiFetchRaw(endpoint, options, base).then((res) => res.json());
};

export const addEmployee = (url, employeeData) => {
  return apiFetch(
    url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(employeeData),
    },
    process.env.REACT_APP_EMPLOYEE_API,
  );
};

export const updateEmployee = (url, employeeData) => {
  return apiFetch(
    url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(employeeData),
    },
    process.env.REACT_APP_EMPLOYEE_API,
  );
};

export const fetchEmployeeDetails = (email) => {
  return apiFetch(
    `v0/employee/details?email=${email}`,
    {},
    process.env.REACT_APP_EMPLOYEE_API,
  ).then((res) => res.employee);
};

export const fetchEmployeeList = (org_id) => {
  return apiFetch(
    `v0/employee/list?org_id=${org_id}`,
    {},
    process.env.REACT_APP_EMPLOYEE_API,
  ).then((res) => res.employees);
};

export const appscan = {
  list: (org_id) => apiFetch(
    `v0/scan?org_id=${org_id}`,
    {},
    process.env.REACT_APP_APPSCAN_API
  ),
  basic: (org_id, url) => apiFetch(
    `v0/scan/basic`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ org_id: org_id, website: url }),
    },
    process.env.REACT_APP_APPSCAN_API
  )
};
